import moment from "moment";

export default {
  type: "purchases-invoices",
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  code: null,
  has_no_taxes: false,
  excerpt: "",
  discounts: [],
  relationshipNames: [
    "issuer",
    "organization",
    "allowedLocations",
    "purchasesOrder",
    "serviceCenter",
    "establishment",
    "cafeteria",
    "destinationWarehouse",
    "paymentTerm",
  ],
  issuer: {
    type: "suppliers",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  purchasesOrder: {
    type: "purchases-orders",
    id: null,
  },
  destinationWarehouse: {
    type: "warehouses",
    id: null,
  },
  items: [],
  allowedLocations: [],
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  cafeteria: {
    type: "cafeterias",
    id: null,
  },
  paymentTerm: {
    type: "payment-terms",
    id: null,
  },
};
