var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.purchasesInvoice.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesInvoice.id},on:{"organizationChanged":_vm.onOrganizationChanged}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.purchasesInvoice.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.purchasesInvoice.organization.id},on:{"locationsChanged":(locations) => {
          _vm.purchasesInvoice.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SERVICECENTERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERVICECENTER')}`,"placeholder":_vm.$t('COMMON.SERVICECENTER')}},[_c('service-center-selector',{attrs:{"serviceCenter":_vm.purchasesInvoice.serviceCenter
          ? _vm.purchasesInvoice.serviceCenter.id
          : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id},on:{"serviceCenterChanged":(serviceCenter) => {
          _vm.purchasesInvoice.serviceCenter.id = serviceCenter;
          _vm.purchasesInvoice.establishment.id = null;
          _vm.purchasesInvoice.cafeteria.id = null;
          _vm.purchasesInvoice.purchasesOrder.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenter}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENT')}`,"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"establishment":_vm.purchasesInvoice.establishment
          ? _vm.purchasesInvoice.establishment.id
          : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id,"filterServiceCenter":_vm.purchasesInvoice.serviceCenter.id},on:{"establishmentChanged":(establishment) => {
          _vm.purchasesInvoice.establishment.id = establishment;
          _vm.purchasesInvoice.cafeteria.id = null;
          _vm.purchasesInvoice.purchasesOrder.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CAFETERIA')}`,"placeholder":_vm.$t('COMMON.CAFETERIA')}},[_c('cafeteria-selector',{attrs:{"cafeteria":_vm.purchasesInvoice.cafeteria ? _vm.purchasesInvoice.cafeteria.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id,"filterServiceCenter":_vm.purchasesInvoice.serviceCenter.id,"filterEstablishment":_vm.purchasesInvoice.establishment.id},on:{"cafeteriaChanged":(cafeteria) => {
          _vm.purchasesInvoice.cafeteria.id = cafeteria;
          _vm.purchasesInvoice.purchasesOrder.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeteria}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PURCHASES_ORDERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PURCHASES_ORDERS')}`,"placeholder":_vm.$t('COMMON.PURCHASES_ORDERS')}},[_c('purchases-order-selector',{attrs:{"allowNone":true,"purchasesOrder":_vm.purchasesInvoice.purchasesOrder
          ? _vm.purchasesInvoice.purchasesOrder.id
          : null,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesInvoice.id,"filterOrganization":_vm.purchasesInvoice.organization.id,"filterStatus":_vm.ORDER_STATUS_VALIDATED,"filterServiceCenter":_vm.purchasesInvoice.serviceCenter.id,"filterEstablishment":_vm.purchasesInvoice.establishment.id,"filterCafeteria":_vm.purchasesInvoice.cafeteria.id},on:{"purchasesOrderChanged":(purchasesOrderId, purchasesOrder) => {
          _vm.purchasesInvoice.purchasesOrder.id = purchasesOrderId;
          if (purchasesOrder) {
            _vm.purchasesInvoice.issuer.id = purchasesOrder.issuer.id;
            if (purchasesOrder.destinationWarehouse) {
              _vm.purchasesInvoice.destinationWarehouse.id =
                purchasesOrder.destinationWarehouse.id;
            }
            _vm.purchasesInvoice.paymentTerm = purchasesOrder.paymentTerm;
          }
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.purchasesOrder}}),_c('base-input',{attrs:{"label":`${_vm.$t('PURCHASES_INVOICES.EXPIRATION_TIME')} (*)`,"placeholder":_vm.$t('PURCHASES_INVOICES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.purchasesInvoice.expiration_time),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "expiration_time", $$v)},expression:"purchasesInvoice.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}}),_c('base-input',{attrs:{"label":`${_vm.$t(`PURCHASES_INVOICES.SELECT_SUPPLIERS`)} (*)`}},[_c('supplier-selector',{attrs:{"allowNone":true,"supplier":_vm.purchasesInvoice.issuer.id,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id,"disabled":!!_vm.purchasesInvoice.id},on:{"supplierChanged":(supplierId) => {
          _vm.purchasesInvoice.issuer.id = supplierId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.issuer}}),_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.DESTINATION_WAREHOUSE`)}`}},[_c('warehouse-selector',{attrs:{"allowNone":true,"warehouse":_vm.purchasesInvoice.destinationWarehouse
          ? _vm.purchasesInvoice.destinationWarehouse.id
          : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id,"disabled":!!_vm.purchasesInvoice.id},on:{"warehouseChanged":(warehouseId) => {
          _vm.purchasesInvoice.destinationWarehouse.id = warehouseId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.destinationWarehouse}}),_c('div',{staticClass:"my-3"},[_c('base-checkbox',{staticClass:"mb-3",on:{"change":_vm.onFormChanged},model:{value:(_vm.purchasesInvoice.has_no_taxes),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "has_no_taxes", $$v)},expression:"purchasesInvoice.has_no_taxes"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("PURCHASES_INVOICES.EXCLUDING_TAXES"))+" ")])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.has_no_taxes}})],1),_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.PAYMENT_TERM`)} (*)`}},[_c('payment-term-selector',{attrs:{"allowNone":false,"paymentTerm":_vm.purchasesInvoice.paymentTerm ? _vm.purchasesInvoice.paymentTerm.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id},on:{"paymentTermChanged":(paymentTermId) => {
          _vm.purchasesInvoice.paymentTerm.id = paymentTermId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.paymentTerm}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesInvoice.excerpt),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "excerpt", $$v)},expression:"purchasesInvoice.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.TERMS_CONDITIONS")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesInvoice.terms_conditions),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "terms_conditions", $$v)},expression:"purchasesInvoice.terms_conditions"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.terms_conditions}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.DESCRIPTION")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesInvoice.description),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "description", $$v)},expression:"purchasesInvoice.description"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.description}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.purchasesInvoice.id ? _vm.$t("PURCHASES_INVOICES.EDIT_PURCHASES_INVOICE") : _vm.$t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }