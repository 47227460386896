<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
    >
      <organization-selector
        :allowNone="true"
        :organization="purchasesInvoice.organization.id"
        :filterable="true"
        :showAll="false"
        :disabled="!!purchasesInvoice.id"
        @organizationChanged="onOrganizationChanged"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="purchasesInvoice.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="purchasesInvoice.organization.id"
        @locationsChanged="
          (locations) => {
            purchasesInvoice.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      :label="`${$t('COMMON.SERVICECENTER')}`"
      :placeholder="$t('COMMON.SERVICECENTER')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
    >
      <service-center-selector
        :serviceCenter="
          purchasesInvoice.serviceCenter
            ? purchasesInvoice.serviceCenter.id
            : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesInvoice.organization.id"
        @serviceCenterChanged="
          (serviceCenter) => {
            purchasesInvoice.serviceCenter.id = serviceCenter;
            purchasesInvoice.establishment.id = null;
            purchasesInvoice.cafeteria.id = null;
            purchasesInvoice.purchasesOrder.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.serviceCenter" />

    <base-input
      :label="`${$t('COMMON.ESTABLISHMENT')}`"
      :placeholder="$t('COMMON.ESTABLISHMENT')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
    >
      <establishment-selector
        :establishment="
          purchasesInvoice.establishment
            ? purchasesInvoice.establishment.id
            : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesInvoice.organization.id"
        :filterServiceCenter="purchasesInvoice.serviceCenter.id"
        @establishmentChanged="
          (establishment) => {
            purchasesInvoice.establishment.id = establishment;
            purchasesInvoice.cafeteria.id = null;
            purchasesInvoice.purchasesOrder.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.establishment" />

    <base-input
      :label="`${$t('COMMON.CAFETERIA')}`"
      :placeholder="$t('COMMON.CAFETERIA')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
    >
      <cafeteria-selector
        :cafeteria="
          purchasesInvoice.cafeteria ? purchasesInvoice.cafeteria.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesInvoice.organization.id"
        :filterServiceCenter="purchasesInvoice.serviceCenter.id"
        :filterEstablishment="purchasesInvoice.establishment.id"
        @cafeteriaChanged="
          (cafeteria) => {
            purchasesInvoice.cafeteria.id = cafeteria;
            purchasesInvoice.purchasesOrder.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.cafeteria" />

    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PURCHASES_ORDERS)"
      :label="`${$t('COMMON.PURCHASES_ORDERS')}`"
      :placeholder="$t('COMMON.PURCHASES_ORDERS')"
    >
      <purchases-order-selector
        :allowNone="true"
        :purchasesOrder="
          purchasesInvoice.purchasesOrder
            ? purchasesInvoice.purchasesOrder.id
            : null
        "
        :filterable="true"
        :showAll="false"
        :disabled="!!purchasesInvoice.id"
        :filterOrganization="purchasesInvoice.organization.id"
        :filterStatus="ORDER_STATUS_VALIDATED"
        :filterServiceCenter="purchasesInvoice.serviceCenter.id"
        :filterEstablishment="purchasesInvoice.establishment.id"
        :filterCafeteria="purchasesInvoice.cafeteria.id"
        @purchasesOrderChanged="
          (purchasesOrderId, purchasesOrder) => {
            purchasesInvoice.purchasesOrder.id = purchasesOrderId;
            if (purchasesOrder) {
              purchasesInvoice.issuer.id = purchasesOrder.issuer.id;
              if (purchasesOrder.destinationWarehouse) {
                purchasesInvoice.destinationWarehouse.id =
                  purchasesOrder.destinationWarehouse.id;
              }
              purchasesInvoice.paymentTerm = purchasesOrder.paymentTerm;
            }
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.purchasesOrder" />

    <base-input
      :label="`${$t('PURCHASES_INVOICES.EXPIRATION_TIME')} (*)`"
      :placeholder="$t('PURCHASES_INVOICES.EXPIRATION_TIME')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          minDate: 'today',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="purchasesInvoice.expiration_time"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.expiration_time" />

    <base-input :label="`${$t(`PURCHASES_INVOICES.SELECT_SUPPLIERS`)} (*)`">
      <supplier-selector
        :allowNone="true"
        :supplier="purchasesInvoice.issuer.id"
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesInvoice.organization.id"
        :disabled="!!purchasesInvoice.id"
        @supplierChanged="
          (supplierId) => {
            purchasesInvoice.issuer.id = supplierId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.issuer" />

    <base-input :label="`${$t(`COMMON.DESTINATION_WAREHOUSE`)}`">
      <warehouse-selector
        :allowNone="true"
        :warehouse="
          purchasesInvoice.destinationWarehouse
            ? purchasesInvoice.destinationWarehouse.id
            : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesInvoice.organization.id"
        :disabled="!!purchasesInvoice.id"
        @warehouseChanged="
          (warehouseId) => {
            purchasesInvoice.destinationWarehouse.id = warehouseId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.destinationWarehouse" />

    <div class="my-3">
      <base-checkbox
        @change="onFormChanged"
        v-model="purchasesInvoice.has_no_taxes"
        class="mb-3"
      >
        <span class="form-control-label">
          {{ $t("PURCHASES_INVOICES.EXCLUDING_TAXES") }}
        </span>
      </base-checkbox>
      <validation-error :errors="apiValidationErrors.has_no_taxes" />
    </div>

    <base-input :label="`${$t(`COMMON.PAYMENT_TERM`)} (*)`">
      <payment-term-selector
        :allowNone="false"
        :paymentTerm="
          purchasesInvoice.paymentTerm ? purchasesInvoice.paymentTerm.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesInvoice.organization.id"
        @paymentTermChanged="
          (paymentTermId) => {
            purchasesInvoice.paymentTerm.id = paymentTermId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.paymentTerm" />

    <h2 class="mt-5">{{ $t("COMMON.NOTE") }}</h2>
    <html-editor v-model="purchasesInvoice.excerpt" @change="onFormChanged()">
    </html-editor>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <h2 class="mt-5">{{ $t("COMMON.TERMS_CONDITIONS") }}</h2>
    <html-editor
      v-model="purchasesInvoice.terms_conditions"
      @change="onFormChanged()"
    >
    </html-editor>
    <validation-error :errors="apiValidationErrors.terms_conditions" />

    <h2 class="mt-5">{{ $t("COMMON.DESCRIPTION") }}</h2>
    <html-editor
      v-model="purchasesInvoice.description"
      @change="onFormChanged()"
    >
    </html-editor>
    <validation-error :errors="apiValidationErrors.description" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          purchasesInvoice.id
            ? $t("PURCHASES_INVOICES.EDIT_PURCHASES_INVOICE")
            : $t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ORDER_STATUS_VALIDATED } from "@/constants/orders";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PurchasesOrderSelector from "@/components/PurchasesOrderSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";
import PaymentTermSelector from "@/components/PaymentTermSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    SupplierSelector,
    PurchasesOrderSelector,
    ServiceCenterSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    WarehouseSelector,
    PaymentTermSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["purchasesInvoiceData", "formErrors", "loading"],

  data() {
    return {
      purchasesInvoice: null,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
    };
  },

  created() {
    let purchasesInvoiceData = { ...this.purchasesInvoiceData };
    purchasesInvoiceData = this.$fillUserOrganizationData(purchasesInvoiceData);
    purchasesInvoiceData = this.$fillUserCafeteriasData(purchasesInvoiceData);
    if (!purchasesInvoiceData.id) {
      purchasesInvoiceData.terms_conditions =
        this.organizationConfig.DEFAULT_PURCHASES_INVOICE_TERMS;
      purchasesInvoiceData.description =
        this.organizationConfig.DEFAULT_PURCHASES_INVOICE_DESCRIPTION;
      purchasesInvoiceData.paymentTerm = {
        type: "payment-terms",
        id: this.organizationConfig.DEFAULT_PURCHASES_PAYMENT_TERMS,
      };
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.purchasesInvoice = purchasesInvoiceData;
  },

  computed: {
    ...mapGetters({
      organizationConfig: "organizationOptions/organizationConfig",
    }),
  },

  methods: {
    async handleSubmit() {
      let purchasesInvoiceData = cloneDeep(this.purchasesInvoice);
      purchasesInvoiceData.expiration_time = moment(
        purchasesInvoiceData.expiration_time
      ).toISOString();
      purchasesInvoiceData =
        this.$fillUserOrganizationData(purchasesInvoiceData);
      purchasesInvoiceData = this.$fillUserCafeteriasData(purchasesInvoiceData);
      if (!purchasesInvoiceData.serviceCenter.id) {
        delete purchasesInvoiceData.serviceCenter;
      }
      if (!purchasesInvoiceData.establishment.id) {
        delete purchasesInvoiceData.establishment;
      }
      if (!purchasesInvoiceData.cafeteria.id) {
        delete purchasesInvoiceData.cafeteria;
      }
      if (purchasesInvoiceData.purchasesOrder) {
        if (!purchasesInvoiceData.purchasesOrder.id) {
          delete purchasesInvoiceData.purchasesOrder;
        }
      }
      this.$emit("purchasesInvoiceSubmitted", purchasesInvoiceData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async onOrganizationChanged(organizationId) {
      if (!organizationId) {
        return;
      }
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch(
        "organizationOptions/getOrganizationConfig",
        organizationId
      );
      this.purchasesInvoice.organization.id = organizationId;
      this.purchasesInvoice.allowedLocations = [];
      this.purchasesInvoice.issuer.id = null;
      this.purchasesInvoice.serviceCenter.id = null;
      this.purchasesInvoice.establishment.id = null;
      this.purchasesInvoice.cafeteria.id = null;
      this.purchasesInvoice.terms_conditions =
        this.organizationConfig.DEFAULT_PURCHASES_INVOICE_TERMS;
      this.purchasesInvoice.description =
        this.organizationConfig.DEFAULT_PURCHASES_INVOICE_DESCRIPTION;
      this.purchasesInvoice.paymentTerm = {
        type: "payment-terms",
        id: this.organizationConfig.DEFAULT_PURCHASES_PAYMENT_TERMS,
      };
      this.onFormChanged();
      window.scrollTo({ top: 0, behavior: "smooth" });
      swal.close();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    purchasesInvoiceData(purchasesInvoiceData) {
      if (purchasesInvoiceData) {
        this.purchasesInvoice = cloneDeep(this.purchasesInvoiceData);
        this.purchasesInvoice = this.$fillUserOrganizationData(
          this.purchasesInvoice
        );
        this.purchasesInvoice = this.$fillUserCafeteriasData(
          this.purchasesInvoice
        );
        if (!this.purchasesInvoice.organization) {
          this.purchasesInvoice.organization = {
            type: "organizations",
            id: null,
          };
        }
        if (!this.purchasesInvoice.serviceCenter) {
          this.purchasesInvoice.serviceCenter = {
            type: "service-centers",
            id: null,
          };
        }
        if (!this.purchasesInvoice.establishment) {
          this.purchasesInvoice.establishment = {
            type: "establishments",
            id: null,
          };
        }
        if (!this.purchasesInvoice.cafeteria) {
          this.purchasesInvoice.cafeteria = {
            type: "cafeterias",
            id: null,
          };
        }
      }
    },
  },
};
</script>
